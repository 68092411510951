<template>
  <CRow class="justify-content-center">
    <Modal :modal="modal"></Modal>

    <!-- Content -->
    <CCol col="12">
      <CForm @submit.prevent="send">
        <!-- Fields -->
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Banners</h4>
            <div class="d-flex align-items-center">
              <div class="ml-2 d-flex align-items-center">
                <label class="mb-0">
                  <b>Status</b>
                </label>
                <CSwitch
                  type="checkbox"
                  color="success"
                  variant="3d"
                  class="mx-2"
                  v-bind="labelIcon"
                  :checked.sync="status"
                />
              </div>
            </div>
          </CCardHeader>

          <CCardBody>
            <CRow>
              <CCol lg="12">
                <FileUploader
                  id="desktopUploader"
                  :uploader-info="desktopUploaderInfo"
                  :enable-multiple="false"
                  :enable-crop-on-uploader="false"
                  :enable-edit-on-api-table="false"
                  :max-files="1"
                  :accept-types="acceptTypes"
                  :extensions="extensions"
                  :medias="desktopMedias"
                  :height="520"
                  :width="1920"
                  uploader-type="banner"
                />
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol lg="12">
                <FileUploader
                  id="mobileUploader"
                  :uploader-info="mobileUploaderInfo"
                  :enable-multiple="false"
                  :enable-crop-on-uploader="false"
                  :enable-edit-on-api-table="false"
                  :max-files="1"
                  :accept-types="acceptTypes"
                  :extensions="extensions"
                  :medias="mobileMedias"
                  :height="576"
                  :width="768"
                  uploader-type="banner"
                />
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol lg="12">
                <h4 class="mb-4">Informações</h4>
                <CRow>
                  <CCol lg="4">
                    <CInput
                      v-model="title"
                      label="Título*"
                      placeholder="Título do banner"
                      required
                    />
                  </CCol>
                  <CCol lg="4">
                    <CInput
                      v-model="link"
                      label="Link"
                      placeholder="https://www.exemplo.com.br"
                    />
                  </CCol>
                  <CCol v-if="link" lg="4">
                    <label for="open-as">Abrir como</label>
                    <Multiselect
                      id="open-as"
                      :value="
                        linkTargetOptions.find(
                          (option) => option.value === linkTargetSelected
                        )
                      "
                      :options="linkTargetOptions"
                      @select="
                        (selected) => (linkTargetSelected = selected.value)
                      "
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="4">
                    <label for="time">Tempo</label>
                    <Multiselect
                      id="time"
                      :value="
                        hasExpirationTimeOptions.find(
                          (option) => option.value === expirationTimeSelected
                        )
                      "
                      :options="hasExpirationTimeOptions"
                      :required="true"
                      @select="
                        (selected) => (expirationTimeSelected = selected.value)
                      "
                    />
                  </CCol>
                  <CCol lg="4">
                    <label>Data de publicação </label>
                    <Datepicker
                      :value="periodOf.value"
                      @selected="periodOfSelected"
                    />
                  </CCol>
                  <CCol v-if="expirationTimeSelected == 'determined'" lg="4">
                    <label>Data de expiração</label>
                    <Datepicker
                      :value="periodUntil.value"
                      @selected="periodUntilSelected"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <!-- Actions -->
        <div class="form-actions">
          <a href="javascript:void(0)" class="mr-3" @click="clearEdit"
            >Limpar alterações</a
          >
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
// components
import FileUploader from '@/components/ui/Fileuploader'
import Datepicker from '@/components/ui/Datepicker'
import Modal from '@/components/ui/Modal'
import Multiselect from '@/components/ui/Multiselect'

// dependencies
import moment from 'moment'
import BannerService from '@/services/banner.service'

export default {
  metaInfo: {
    title: 'Banners',
    titleTemplate: 'Manager  - OW Interactive - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_banners')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    FileUploader,
    Datepicker,
    Modal,
    Multiselect
  },

  data() {
    return {
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      status: true,
      periodUntil: {
        value: moment().toDate()
      },
      periodOf: {
        value: moment().toDate()
      },
      link: null,
      title: null,
      linkTargetSelected: '_self',
      linkTargetOptions: [
        {
          value: '_blank',
          label: 'Nova janela'
        },
        {
          value: '_self',
          label: 'Mesma janela'
        }
      ],
      expirationTimeSelected: [],
      hasExpirationTimeOptions: [
        {
          value: 'indeterminate',
          label: 'Indeterminado'
        },
        {
          value: 'determined',
          label: 'Determinado'
        }
      ],
      acceptTypes: 'image/png,image/jpeg',
      extensions: 'jpg,jpeg,png',
      desktopUploaderInfo: {
        label: 'Banner Desktop',
        recomendation: `O texto deve ficar em uma área centralizada com a largura de 1120px,
        <a class="text-primary" href="${process.env.BASE_URL}banner-desktop-example.jpg" download>veja o exemplo</a>`
      },
      mobileUploaderInfo: {
        label: 'Banner Mobile',
        recomendation: `O texto deve ficar em uma área centralizada com a largura de 612px.
        <a class="text-primary" href="${process.env.BASE_URL}banner-mobile-example.jpg" download>veja o exemplo</a>`
      },
      hasLinkOptions: [
        {
          value: '0',
          label: 'Não'
        },
        {
          value: '1',
          label: 'Sim'
        }
      ],
      loadedDesktopMedias: [],
      loadedMobileMedias: [],
      bannerId: null,
      desktopMedias: [],
      mobileMedias: [],
      processing: false,
      new: true,
      query: {
        sorter: {}
      },
      modal: {
        show: false,
        title: '',
        message: '',
        color: ''
      },
      baseUrl: ''
    }
  },

  async created() {
    if (this.$route.params.id) {
      await this.setBannerData()
    }
  },

  methods: {
    async setBannerData() {
      if (this.$route.params.id) {
        const banner = await BannerService.getBanner(this.$route.params.id)
        if (banner.medias.length > 0 || banner.mediasMobile.length > 0) {
          this.bannerId = banner.id
          this.link = banner.anchor_url
          this.title = banner.heading
          this.status = banner.status === 1
          this.linkTargetSelected = banner.anchor_target
          this.desktopMedias = banner.medias
          this.mobileMedias = banner.mediasMobile
          this.new = false

          if (banner.end_at && banner.begin_at) {
            this.expirationTimeSelected = 'determined'
            this.periodUntil.value = banner.end_at
            this.periodOf.value = banner.begin_at
          } else {
            this.expirationTimeSelected = 'indeterminate'
            this.periodOf.value = banner.begin_at
          }

          this.$forceUpdate()
        }
      }
    },
    /**
     * Reset edits made
     */
    async clearEdit() {
      await this.setBannerData()
    },

    periodOfSelected(date) {
      this.periodOf.value = moment(date).toDate()
    },
    /**
     * Formats date of periodUntil
     */
    periodUntilSelected(date) {
      this.periodUntil.value = moment(date).toDate()
    },

    async send() {
      this.processing = true

      if (!this.desktopMedias.length > 0 || !this.mobileMedias.length > 0) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Por favor preencha a imagem desktop e a imagem mobile',
          color: 'danger'
        }
        this.processing = false
      } else {
        let response
        let message

        const data = {
          heading: this.title,
          anchor_url: this.link,
          anchor_target: this.linkTargetSelected,
          display: 1,
          status: this.status,
          medias: this.desktopMedias.map((media) => media.id),
          medias_mobile: this.mobileMedias.map((media) => media.id)
        }
        if (this.expirationTimeSelected === 'determined') {
          data.begin_at = moment.utc(this.periodOf.value).format('YYYY-MM-DD')
          data.end_at = moment.utc(this.periodUntil.value).format('YYYY-MM-DD')
        } else {
          data.begin_at = moment.utc(this.periodOf.value).format('YYYY-MM-DD')
        }

        if (!this.new) {
          response = await BannerService.updateBanner(this.bannerId, data)
          message = 'O banner foi atualizado com sucesso!'
        } else {
          response = await BannerService.storeBanner(data)
          message = 'O banner foi cadastrado com sucesso!'
          this.new = false
        }

        this.processing = false

        if (response.status === 200) {
          this.modal = {
            show: true,
            title: 'Sucesso',
            message: message,
            color: 'success',
            redirect: true
          }

          setTimeout(() => {
            // this.$router.go(-1)
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else if (response.status === 400) {
          this.modal = {
            show: true,
            title: 'Erro',
            message: `${
              response.data.length > 0
                ? response.data[0].message
                : 'Preencha todos os campos corretamente!'
            }`,
            color: 'danger'
          }
        } else {
          this.modal = {
            show: true,
            title: 'Erro',
            message: 'Ocorreu um erro interno, por favor tente novamente!',
            color: 'danger'
          }
        }
      }
    }
  }
}
</script>
